.ui.card.carousel-container {
  width: 100%;
  margin-bottom: 70px;
  box-shadow: 0px 0px;
}

.content.carousel {
  padding: 0px !important;
  margin-bottom: 10px;
}

.center {
  text-align: center;
}
.ui.segment.slide.right {
  height: 300px;
  vertical-align: middle;
}

.ui.very.relaxed.two.column.grid {
  margin-top: 70px;
}

.ui.circular.label,
.ui.circular.labels .label {
  padding: 0.35em !important;
}

.ui.segment.slide.right.visible.transition {
  margin: 0px !important;
}

.slider-link {
  box-sizing: content-box;
  -webkit-flex-grow: 0 !important;
  flex-grow: 0 !important;
  margin: 10px 0px;
}

.columns {
  margin: 30px !important;
}

.explore {
  margin: 80px 40px;
  text-align: center;
  border-bottom: 1px solid #9c94946b;
  padding: 20px;
}

.learn {
  margin: 80px 40px;
  text-align: center;
  border-bottom: 1px solid #9c94946b;
  padding: 20px;
}

.learn-column {
  margin-top: 80px !important;
}

.horizontal-padding {
  padding: 10px 0px;
}

.hp-description {
  margin: 10px 0px;
  color: black;
}

.grid-featured {
  font-size: 18px;
  line-height: 2em;
  margin: 20px 0px;
}
