.tableMatrix {
  width: 100%;
  padding: 20px 0px;
  /*background: #f7f7f7;*/
  overflow-x: scroll;
}

.isTable {
  min-width: 90% !important;
}

.ui.basic.table {
  padding: 7px;
  /*box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.17) !important;*/
  border-radius: 0px !important;
  margin: 0px auto;
  background: white !important;
}
