.language-option {
  display: inline;
  margin: 10px;
}

.language-options {
  width: 100%;
  text-align: center;
  margin: 10px auto;
}
.ui.image.label {
  font-weight: 300;
  line-height: 0.5;
  width: 100% !important;
  background: #d88c8cfa !important;
}
.margin-top {
  margin-top: 20px !important;
}
