.google {
  margin: 10px 0px;
}

.google-icon {
  margin: 0px 5px !important;
}

.button-label {
  margin-left: 10px;
}

.login-header {
  background: white;
}

.google-button,
.google-button:hover {
  background: #d9534f;
  color: white;
  padding: 10px;
}
.vertical-super {
  vertical-align: middle;
}

.google-icon {
  margin-top: -3px !important;
}

.loginp-link {
  display: inline;
  margin: 10px;
  color: orange;
}

.loginp-link:hover {
  color: #d9534f;
}
