@import url("https://fonts.googleapis.com/css?family=Iceland");
@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@font-face {
  font-family: "Fipps";
  src: url("./fonts/Fipps-Regular.otf");
}

@font-face {
  font-family: "VCR";
  src: url("./fonts/VCR_OSD_MONO_1.001.ttf");
}

@font-face {
  font-family: "LCD";
  src: url("./fonts/Pixel\ LCD-7.ttf");
}
@font-face {
  font-family: "BIT";
  src: url("./fonts/8-BIT_WONDER.TTF");
}
@font-face {
  font-family: "Pixel";
  src: url("./fonts/PixelOperator.ttf");
}

header,
nav,
section,
article,
footer,
a,
ul,
li,
p,
pre {
  display: block;
  margin: 0px;
  padding: 0px;
  /*font-family: "Iceland", Regular-Italic;*/
}

.form-input-control-error-name {
  width: 100% !important;
}

.css-1pcexqc-container {
  width: 100% !important;
}

.child-content {
  background: #e9faff;
  padding-left: 20px;
  padding-right: 20px;
}

.child-content > div > .child-content {
  background: #f8fdff !important;
  /* padding-left: 20px;
  padding-right: 20px; */
}

.form-label {
  font-weight: 600;
  font-size: 15px;
}
.form-input {
}

.pull-right {
  float: right;
}

.form-field {
  padding: 10px 0px;
}

.huegreen {
  color: #f5be74;
}
.ui.segment.pusher {
  margin: 0px !important;
  padding: 0px !important;
}

.content.section {
  padding: 0px 40px;
}

.editorWrapper {
  height: 500px;
  border: 1px solid #d8d7d7;
  overflow: scroll;
}

.rdw-editor-main {
  padding: 20px;
  overflow: visible;
}

.item {
  padding: 10px;
}

.content {
  padding: 10px 0px;
  margin-top: 10px;
}

.ui.divided.list .list > .item:first-child,
.ui.divided.list > .item:first-child > .content {
  margin-top: 10px;
}

.nav-grid {
  padding: 0px !important;
  margin: 0px !important;
}

.samaj {
  border: 1px solid #dededf9e;
  width: 200px;
  text-align: center;
  padding: 40px;
  background: #ff6a00bf;
  margin: 20px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.samaj:hover {
  border: 1px solid #dededf;
  background: 1px solid #dededf;
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.ui.simple.dropdown:hover > .menu {
  width: 100% !important;
}

.properties-description {
  padding: 20px 0px;
}

.private-button {
  background: #6495ed !important;
  color: white !important;
}

.major-padding {
  padding: 10px 0px !important;
  line-height: 20px !important;
}

.privacy {
  cursor: pointer;
  /* line-height: 1.38; */
  /* padding: 10px 0px; */
}

.app .App {
  min-height: 100vh;
}

.tableMatrix {
  min-height: 500px;
}

.footer {
  background: white;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  top: calc(100% - 60px);
}

.ui.menu.fluid,
.ui.vertical.menu.fluid {
  min-height: 500px;
}

.ui.tabular.menu .item {
  /*color: brown !important;*/
}

.ui.vertical.tabular.menu .active.item {
  background: brown;
  color: white;
}

.row-container {
  vertical-align: top;
  max-width: 450px;
}

.row-property {
  vertical-align: middle;
  max-width: 450px;
}

.add_icon {
  border: 1px solid #000000;
  padding: 8px;
  border-radius: 50px;
  font-size: 14px !important;
  display: unset !important;
  color: #585858;
}

.form,
.main_section {
  width: 80%;
  margin: 0px auto !important;
  padding: 20px 0px !important;
  border-bottom: 1px solid #e4e4e4;
}

.pull-left {
  float: left;
}

.content-heading.smallprint {
  text-decoration: none;
  font-size: 12px;
  color: grey;
  line-height: 0.5px;
  padding: 5px 0px;
}

.back-link,
.back-link:hover {
  color: grey;
}

.pull-right {
  float: right;
}

.font-16 {
  font-size: 16px;
  padding: 20px 10px;
  cursor: pointer;
}

.left-border {
  border-left: 1px solid #e4e4e4;
}

.no-left-right-padding {
  padding: 1rem 0px !important;
}

.hamburger {
  display: none !important;
  background: #a65cc8 none !important;
  color: white !important;
}

.hamburger:hover {
  background: #d490f3 none !important;
}

.section_content {
  width: 80%;
  margin: 40px auto;
}

.content-heading {
  font-size: 18px;
  line-height: 1.8em;
  padding: 20px 0px;
}

.content-subheading {
  font-size: 14px;
}

.index_flex {
  margin-top: 100px;
  display: grid;
  clear: both;
}

@media (min-width: 320px) {
  .hamburger {
    display: inline !important;
  }
  .headerUserDropDown {
    display: none !important;
  }
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  .hamburger {
    display: inline !important;
  }
  .headerUserDropDown {
    display: none !important;
  }
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  .hamburger {
    display: inline !important;
  }
  .headerUserDropDown {
    display: none !important;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  .hamburger {
    display: none !important;
  }
  .headerUserDropDown {
    display: inline !important;
  }
}
@media (min-width: 1025px) {
  .hamburger {
    display: none !important;
  }
  .headerUserDropDown {
    display: inline !important;
  }
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  .hamburger {
    display: none !important;
  }
  .headerUserDropDown {
    display: inline !important;
  }
  /* hi-res laptops and desktops */
}
