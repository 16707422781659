.root-section{
padding: 10px;
border: 1px solid #d0cece;
margin: 20px !important;
}

.root-header{
    padding:10px;
    display: block;
    width:100%;
    margin-left:10px;
    font-size:16px;
    margin-top:10px !important;
    font-weight: 600;
    color:brown;
}

.home-section{
    min-height: 400px;
}

.root-column{
    border: 1px solid #e2e2e2;
    padding: 10px !important;
    margin: 10px 20px 20px 20px;
    text-align:left;
    line-height: 1.6em;
}

.root-description{
    color: #9c9999;
}

