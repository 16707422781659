.header {
  max-height: 120px;
  /* background: #f54040; */
  background: white;
  color: black;
  border-bottom: 1px solid #e4e4e4;
}

.logo {
  width: 60px;
}

.header > .ui.secondary.menu {
  padding: 0px 40px;
}

.secondary_custom {
  padding: 10px 40px;
}

.authentication_buttons {
  color: black;
}

.black {
  color: black;
}

.header h2 {
  /*font-family: "Press Start 2P", Extra-Bold cursive;*/
  color: white;
}

.white {
  color: white !important;
}

.linker {
  color: white;
  margin: 0px 5px 0px 30px;
}

.linker-nav {
  padding: 5px 0px;
}

.menu.transition.visible {
  border-radius: 0px !important;
  z-index: 9999 !important;
}

.border-bottom {
  border-bottom: 1px solid #e0dfdf !important;
  border-radius: 0px !important;
}
