.add-link {
  margin: 10px 0px;
  background: #03bce6;
  padding: 9px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
}

.del-link {
  margin-top: -10px;
  border-radius: 0px 0px 5px 5px;
  background: red;
  color: white;
  font-size: 12px;
  padding: 3px;
}

.array-link {
  margin: 10px 0px;
}
