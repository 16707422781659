.nav {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
}

.ui.grid > .row {
  padding: 0px !important;
}

#uigrid {
  margin-top: 0px !important;
  padding-bottom: 20px !important;
  min-height: 500px;
}

.nav ul {
  margin: 0px;
  padding: 0px;
}

.nav .column {
  display: inline;
  color: black;
  font-family: "Pixel";
  min-width: max-content;
}

.ui.grid > .row > .column > a {
  /*padding: 10px;*/
}

.nav-active,
.nav-active > .ui.dropdown {
  color: white !important;
  background: rgba(34, 36, 38, 0.15);
}

.ui.grid > .row > .column > a {
  transition: background-color 0.5s ease;
}

.ui.grid > .row > .column:hover > a {
  color: white !important;
  background: #9c525c;
}

.nav-link {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 0.9rem;
  text-transform: none;
  font-weight: 500;
  box-shadow: none;
  color: #000000 !important;
  padding: 10px 10px !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.nav-grid {
  padding: 0px !important;
  margin: 0px !important;
}

.nav-dropdown {
  padding: 10px !important;
  font-size: 18px !important;
  width: 100% !important;
}

.nav-dropdown-header {
  margin: 0px !important;
  text-align: center;
  padding: 10px 0px !important;
  /* padding-bottom: 20px !important; */
  /* /* height: auto !important; */
  /* font-size: 16px !important; */
  /* background: white; */
}
.nav-dropdown-link {
  padding: 10px 8px;
  font-size: 14px !important;
  text-underline-position: under;
  color: black;
  border-bottom: 1px solid #dad7d7;
}

.nav_ul {
  padding: 0px !important;
}
